import React from "react";

function PricingSection() {
  return (
    <section id="pricing" className="p-5">
      <div className="container mt-3">
        <h2 className="mb-3">
          <strong>See our Plans</strong>
        </h2>
        <h5 className="mb-5">
          Our pricing model is simple. We offer <b>Free</b> and <b>PRO</b> plan.
        </h5>

        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th></th>
                <th>
                  <strong>Free Plan</strong>
                </th>
                <th>
                  <strong>Pro Plan</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pricing</td>
                <td>$0</td>
                <td>$4.99 USD / month</td>
              </tr>
              <tr>
                <td>No. of emails processed</td>
                <td>30</td>
                <td>3000</td>
              </tr>
              <tr>
                <td>API Accessible</td>
                <td>Y</td>
                <td>Y</td>
              </tr>
              <tr>
                <td>No. of email address available</td>
                <td>3</td>
                <td>300</td>
              </tr>
              <tr>
                <td>Email address format</td>
                <td>Random</td>
                <td>
                  Customizable <br />
                  (under MailEX domain)
                </td>
              </tr>
              <tr>
                <td>Email Source</td>
                <td>MailEX</td>
                <td>MailEX / Gmail / Outlook</td>
              </tr>
              <tr>
                <td>Preset Extraction Targets</td>
                <td>Passcode / Action Links</td>
                <td>Passcode / Action Links / PDF / Images</td>
              </tr>
              <tr>
                <td>Customizable Extraction Targets</td>
                <td>RegExp / XPath</td>
                <td>RegExp / XPath / LLM Prompt Results</td>
              </tr>
              <tr>
                <td>Downstream Handling</td>
                <td>Email</td>
                <td>Email / Slack / Zapier</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default PricingSection;
