// App.js
import React from 'react';
import Navbar from './components/Navbar';
import CTASection from './components/CTASection';
import DemoSection from './components/DemoSection';
import FeaturesSection from './components/FeaturesSection';
import PricingSection from './components/PricingSection';
import GetStartedSection from './components/GetStartedSection';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <CTASection />
      <DemoSection />
      <FeaturesSection />
      <PricingSection />
      <GetStartedSection />
      <Footer />
    </div>
  );
}

export default App;