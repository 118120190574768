import React, { useState, useEffect } from "react";

function GetStartedSection() {
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    // Check for URL parameters indicating form submission status
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("status");
    if (status === "success") {
      setSubmitStatus("success");
    } else if (status === "error") {
      setSubmitStatus("error");
    }

    // Clear status parameter from URL
    if (status) {
      const newUrl = window.location.pathname + window.location.hash;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, []);

  return (
    <section id="getstarted" className="p-5 bg-getstarted-section">
      <div className="container mt-3">
        <h2 className="mb-3">
          <strong>Get Started</strong>
        </h2>
        <div className="mb-3">
          MailEX is now available on Rapid API. Get started with us now.
        </div>
        <div className="mb-5">
          <a
            href="https://rapidapi.com/simpligy-simpligy-default/api/mailex-api"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-primary">
              <strong>Get started via Rapid API</strong>
            </button>
          </a>
        </div>
        <div className="mb-3">
          Again, we offer <strong>API and SDK</strong> for you to easily
          integrate with us. See our documentation for more information.
        </div>
        <div className="mb-5">
          <a
            href="https://rapidapi.com/simpligy-simpligy-default/api/mailex-api"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-secondary">Documentation</button>
          </a>
        </div>
        <div className="mb-3">
          Otherwise, if you have any questions, we are always here to help.{" "}
          <br />
          Simply leave us a message and we will get back soon.
        </div>
        <form action="https://submit-form.com/NPqUkcXj5" method="POST">
          <input
            type="hidden"
            name="_redirect"
            value={`${window.location.href}?status=success`}
          />
          <label className="form-label">Name</label>
          <div className="mb-3">
            <input name="name" type="text" className="form-control" required />
          </div>
          <label className="form-label">Email</label>
          <div className="mb-3">
            <input
              name="email"
              type="email"
              className="form-control"
              required
            />
          </div>
          <label className="form-label">Message</label>
          <div className="mb-3">
            <textarea name="message" className="form-control" required />
          </div>
          <div className="mb-3">
            <button className="btn btn-secondary form-control" type="submit">
              Submit
            </button>
          </div>
        </form>
        {submitStatus === "success" && (
          <div className="alert alert-success" role="alert">
            Your message has been sent successfully!
          </div>
        )}
        {submitStatus === "error" && (
          <div className="alert alert-danger" role="alert">
            An error occurred. Please try again later.
          </div>
        )}
      </div>
    </section>
  );
}

export default GetStartedSection;
