import React from "react";

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div className="container">
        <a className="navbar-brand" href="#">
          <h2><strong>MailEX</strong></h2>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="#demo">
                <strong>Demo</strong>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#features">
                <strong>Features</strong>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#pricing">
                <strong>Pricing</strong>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#getstarted">
                <strong>Get Started</strong>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
