import React from "react";

function Footer() {
  return (
    <div className="container-fluid bg-dark text-white">
      <div className="container p-5">
        <div className="row">
          <div className="col-md-9">
            {/* <a href="/terms-and-conditions" className="text-white">Terms and Conditions</a>
            <span className="mx-3">| </span>
            <a href="/privacy-policy" className="text-white">Privacy Policy</a>
            <span className="mx-3">| </span> */}
            {/* <a href="/contact-us" className="text-white">Contact Us</a> */}
            <div className="text-white">
              <i class="bi bi-envelope-at"></i>
              <a href="mailto:contact@simpligy.io" className="text-white">
                <span className="mx-2">contact@simpligy.io</span>
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <b>MailEX</b> by Simpligy, 2024
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
