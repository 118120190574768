import React from "react";

function CTASection() {
  return (
    <section id="cta" className="bg-cta-section p-5">
      <div className="container p-2 mt-5 mb-5">
        <h1>
          Automate Email Workflows
          <br />
          with <strong>MailEX</strong>
        </h1>
        <h3 className="mt-5">
        <strong>Automate</strong> and Streamline Your Email Workflow with Smart and Customisable <strong>Extraction</strong> and Integration
        </h3>
        <a href="#getstarted" className="btn btn-primary btn-md mt-5">
          <b>Get Started for Free</b>
        </a>
      </div>
    </section>
  );
}

export default CTASection;
