import React, { useState, useRef, useCallback, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const MAILFLOW_BASE_URL = process.env.REACT_APP_API_URL;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const ENABLE_RECAPTCHA = process.env.NODE_ENV === 'production';

function DemoSection() {
  const [receiverAddress, setReceiverAddress] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [commandLine, setCommandLine] = useState("");
  const [emails, setEmails] = useState([]);
  const [showEmails, setShowEmails] = useState(false);
  const [showCommandLine, setShowCommandLine] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const recaptchaRef = useRef();
  const textareaRef = useRef(null);


  // Function to adjust textarea height
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Use effect to adjust textarea height when commandLine changes
  useEffect(() => {
    adjustTextareaHeight();
  }, [commandLine, showCommandLine]);


  const onClickSelectAll = (event) => {
    event.target.select();
  };

  const executeRecaptcha = useCallback(async (action) => {
    if (!ENABLE_RECAPTCHA) {
      return "";
    }
    if (!recaptchaRef.current) {
      throw new Error("reCAPTCHA not loaded");
    }
    try {
      await recaptchaRef.current.reset();
      const token = await recaptchaRef.current.executeAsync(action);
      if (!token) {
        throw new Error("reCAPTCHA verification failed");
      }
      return token;
    } catch (error) {
      console.error("reCAPTCHA error:", error);
      throw error;
    }
  }, []);

  const generateReceiver = useCallback(async () => {
    setIsLoading(true);
    setError("");
    try {
      const recaptchaToken = await executeRecaptcha("generate_receiver");
      const response = await fetch(`${MAILFLOW_BASE_URL}/demo/receivers`, {
        method: "POST",
        headers: {
          "x-recaptcha-token": recaptchaToken,
          "x-recpatcha-action": "generate_receiver",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const { data } = await response.json();
      setReceiverAddress(data.receiverAddress);
      setApiKey(data.apiKey);
      setCommandLine(`curl -H 'x-api-key: ${data.apiKey}' '${MAILFLOW_BASE_URL}/receivers/${data.receiverAddress}/emails'`);
    } catch (err) {
      setError(`Failed to generate receiver address: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [executeRecaptcha]);

  const onLoadExtractionButtonClicked = useCallback(async () => {
    setShowEmails(false);
    setIsLoading(true);
    setError("");
    if (!receiverAddress) {
      setError("Please generate a receiver address first");
      setIsLoading(false);
      return;
    }
    try {
      const emailResponse = await fetch(
        `${MAILFLOW_BASE_URL}/receivers/${receiverAddress}/emails`,
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      if (!emailResponse.ok) {
        throw new Error(`HTTP error! status: ${emailResponse.status}`);
      }
      const { data } = await emailResponse.json();
      setEmails(data);
      setShowEmails(true);
      setShowCommandLine(false);
    } catch (err) {
      setError(`Failed to fetch emails: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, receiverAddress]);

  const onLoadAPIButtonClicked = () => {
    setShowCommandLine(true);
    setShowEmails(false);
  };

  return (
    <section id="demo" className="p-5">
      <div className="container mt-3">
        <h2 className="mb-3">
          <strong>How MailEX works</strong>
        </h2>
        <h5 className="mb-5">
          Allow us to demonstrate it now in <b>30 seconds</b>. Let's try it out!
        </h5>

        {error && <div className="alert alert-danger">{error}</div>}

        <div className="mb-4">
          <h5>
            <b>Step 1:</b>
          </h5>
          <div className="p-2">
            <div className="mb-3">
              MailEX provides you unique email addresses to receive your emails
              for processing.
            </div>
            <div className="container">
              <div className="row">
                <button
                  className="btn btn-secondary me-2 col-md-2"
                  onClick={generateReceiver}
                  disabled={receiverAddress || isLoading}
                >
                  Generate
                </button>
                <div className="col-md-6 p-0 mt-2 mt-md-0">
                  <input
                    type="text"
                    className="form-control input-sm"
                    value={receiverAddress}
                    placeholder="@mailex.simpligy.io"
                    readOnly
                    onClick={onClickSelectAll}
                  />
                </div>
              </div>
            </div>
            {ENABLE_RECAPTCHA && <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={RECAPTCHA_SITE_KEY}
            />}
          </div>
        </div>

        <div className="mb-4">
          <h5>
            <b>Step 2:</b>
          </h5>
          <div className="p-2">
            <div className="mb-3">
              <b>Send or Forward your emails to the address obtained.</b> You
              may even use the address directly to register for other
              services.
            </div>
            <div>
              <b>MailEX will extract the data you needed automatically.</b>
              <br />
              For demo purpose, we will only extract any OTP / Links for you. More extractions
              available on Paid plans.
            </div>
          </div>
        </div>

        <div className="mb-2">
          <h5>
            <b>Step 3:</b>
          </h5>
          <div className="p-2">
            <div className="mb-3">
              <b>Access the extractions programmatically via APIs.</b>
              <br /> Custom webhook or more integrations are available on Paid plans.
            </div>
            <div className="container mb-3" hidden={!receiverAddress}>
              <div className="row">
                <button
                  className="btn btn-secondary me-2 col-md-3"
                  onClick={onLoadExtractionButtonClicked}
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Show Extractions'}
                </button>
                <button
                  className="btn btn-secondary col-md-3 mt-2 mt-md-0"
                  onClick={onLoadAPIButtonClicked}
                  disabled={isLoading}
                >
                  Load via API
                </button>
              </div>
            </div>

            <div>
              {showEmails &&
                receiverAddress &&
                (emails.length > 0 ? (
                  <div className="mt-4 table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Sender</th>
                          <th>Subject</th>
                          <th>Extraction</th>
                        </tr>
                      </thead>
                      <tbody>
                        {emails.map((email) => (
                          <tr key={email.id}>
                            <td>{formatTime(email.date)}</td>
                            <td>{email.senderAddress}</td>
                            <td>{email.subject}</td>
                            <td>{email.extraction.value}</td>
                              {/* <button
                                className="btn btn-sm btn-primary"
                                onClick={() =>
                                  onShowExtractionClicked(email.id)
                                }
                              >
                                Show Extraction
                              </button>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* {Object.entries(extractions).map(
                      ([emailId, extraction]) => (
                        <div key={emailId} className="mt-3">
                          <h6>Extraction for Email ID: {emailId}</h6>
                          <pre>{JSON.stringify(extraction, null, 2)}</pre>
                        </div>
                      )
                    )} */}
                  </div>
                ) : (
                  <div>
                    We havn't see any emails. Please try sending an email to{" "}
                    <strong>{receiverAddress}</strong> and <span role="button" onClick={onLoadExtractionButtonClicked}><u>reload</u></span>.
                  </div>
                ))}

              {showCommandLine && receiverAddress && (
                <textarea
                  ref={textareaRef}
                  readOnly
                  className="mt-4 code form-control"
                  onClick={onClickSelectAll}
                  value={commandLine}

                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DemoSection;
