import React from "react";

function FeaturesSection() {
  return (
    <section id="features" className="p-5 bg-features-section">
      <div className="container mt-3">
        <h2 className="mb-3">
          <strong>Why choose MailEX</strong>
        </h2>
        <h5 className="mb-5">
          Enjoy the intelligent email automation now with us
        </h5>

        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="p-md-3">
                <h5 className="mb-4">
                  <strong>🔍 Smart Extraction 🎯</strong>
                </h5>
                <div className="mb-3">
                  📧 MailEX automatically extracts important information such as
                  OTPs, links, PDFs and images from your emails.
                </div>
                <div className="mb-3">
                  🛠️ You may also configure RegExp or XPath to precisely define
                  your extraction targets based on specific needs, ensuring
                  accuracy and flexibility.
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="p-md-3">
                <h5 className="mb-4">
                  <strong>🤖 AI / LLM capabilities ⚙️</strong>
                </h5>
                <div className="mb-3">
                  💬 MailEX incorporates with AI and Large Language Model (LLM)
                  capabilities to output with generative results such as
                  summarizing and auto-replying.
                </div>
                <div className="mb-3">
                  💡 Simply configure your LLM prompt and let MailEX handle the
                  rest, automating your email processing efficiently.
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="p-md-3">
                <h5 className="mb-4">
                  <strong>📬 Flexible data sources 💻</strong>
                </h5>
                <div className="mb-3">
                  🌐 MailEX provides options for users to load emails from
                  various common email providers like Gmail and Outlook as
                  source.
                </div>
                <div className="mb-3">
                  🔌 We also offer API & SDK that accepts email content for
                  system integrations, making it ideal in many automation use
                  cases.
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="p-md-3">
                <h5 className="mb-4">
                  <strong>📲 Seamless Integration 🔗</strong>
                </h5>
                <div className="mb-3">
                  🔔 MailEX provides options to notify or pass results to
                  downstream services via various channels including Email,
                  Slack, and more by Zapier.
                </div>
                <div className="mb-3">
                  ⚙️ The integrations ensure MailEX to fit perfectly within your
                  current ecosystem, providing a smooth and efficient
                  experience.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;
